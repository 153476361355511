var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-0 container-fluid mt-0"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "row justify-content-center bengali-text pb-1"
  }, [_vm._v(" দুর্দান্ত খবর !! একই অ্যাপ এ সব কিছু? eপরিষেবা - এখন এসে গিয়েছে দুর্গাপুরে , বালুরঘাটে , গঙ্গারামপুরে, মালদা, শিলিগুড়ি ও মেদিনীপুরে ।।। এখন আপনার শহরেও "), _c('a', {
    staticClass: "ml-3 download-text-black-color",
    attrs: {
      "target": "_blank",
      "href": "https://play.google.com/store/apps/details?id=com.eparisheva.client&hl=en_IN&gl=US"
    }
  }, [_vm._v(" Download Here ")])])])]);
}]

export { render, staticRenderFns }